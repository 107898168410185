<template>
    <div
        id="launcher"
        ref="analytics"
        class="launcher launcher--analytics"
    >
        <h1>{{ appName }}: Top level Metrics</h1>

        <div class="launcher__stats">
            <p class="launcher__stat">
                <img src="@/assets/images/launcher/people.png" alt="" class="launcher__stat-image" />
                <span class="launcher__stat-title">Total unique sessions</span>
                <span class="launcher__stat-number">{{ totalSessions }}</span>
            </p>

            <p class="launcher__stat">
                <img src="@/assets/images/launcher/gesture-tap.png" alt="" class="launcher__stat-image" />
                <span class="launcher__stat-title">Total clicks</span>
                <span class="launcher__stat-number">{{ totalClicks }}</span>
            </p>
            <p class="launcher__stat">
                <img src="@/assets/images/launcher/document-search.png" alt="" class="launcher__stat-image" />

                <span class="launcher__stat-title">Total page views</span>
                <span class="launcher__stat-number">{{ pageViewCount }}</span>
            </p>
        </div>

        <div class="overview">
            <div class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/document-2.png" alt="" class="overview__image" />

                    <div class="overview__title">
                        Question views count
                    </div>
                </div>
                <div class="overview__content">
                    <ul>
                        <li v-for="[questionId, count] in Object.entries(questionViewsCount)" :key="questionId">
                            {{ `Question ${parseInt(questionId) + 1}` }} - <span class="overview__content--total">{{ count }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/gesture-tap.png" alt="" class="overview__image" />

                    <div class="overview__title">
                        Screen interactions per day
                    </div>
                </div>
                <div class="overview__content">
                    <ol>
                        <li v-for="(interactionCount, index) in interactionsPerDay" :key="index">
                            {{ convertDate(index) }} - <span class="overview__content--total">{{ interactionCount }}</span>
                        </li>
                    </ol>
                </div>
            </div>

            <div class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/calendar.png" alt="" class="overview__image" />

                    <div class="overview__title">
                        User sessions per day
                    </div>
                </div>
                <div class="overview__content">
                    <ol>
                        <li v-for="(dayCount, index) in userSessionPerDay" :key="index">
                            {{ convertDate(index) }} - <span class="overview__content--total">{{ dayCount }}</span>
                        </li>
                    </ol>
                </div>
            </div>

            <div class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/clock.png" alt="" class="overview__image" />
                    <div class="overview__title">
                        Avg time spent per session
                    </div>
                </div>
                <div class="overview__content">
                    <span class="overview__stat-number">{{ avgTimeSpent }}</span>
                </div>
            </div>

            <div class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/document-2.png" alt="" class="overview__image" />
                    <div class="overview__title">
                        Avg feedback rating
                    </div>
                </div>
                <div class="overview__content">
                    <span class="overview__stat-number">{{ averageFeedbackRating }}</span>
                </div>
            </div>
        </div>

        <div class="overview overview--two-columns">
            <div class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/document-2.png" alt="" class="overview__image" />
                    <div class="overview__title">
                        Questions accuracy
                    </div>
                </div>
                <div class="overview__content">
                    <table class="overview__table">
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Correct</th>
                                <th>Incorrect</th>
                                <th>Accuracy</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="[questionId, { correct, incorrect, accuracy, openQuestion }] in Object.entries(questionAccuracy)" :key="questionId">
                                <td>{{ `Question ${parseInt(questionId) + 1}` }}</td>
                                <td v-if="openQuestion">
                                    open question
                                </td>
                                <td v-else>
                                    {{ correct }}
                                </td>
                                <td v-if="!openQuestion">
                                    {{ incorrect }}
                                </td>
                                <td v-if="!openQuestion">
                                    {{ `${accuracy.toFixed(2)}%` }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/document-2.png" alt="" class="overview__image" />
                    <div class="overview__title">
                        Average time per question
                    </div>
                </div>
                <div class="overview__content">
                    <ul>
                        <li v-for="[questionId, time] in Object.entries(averageTimePerQuestion)" :key="questionId">
                            {{ `Question ${parseInt(questionId) + 1}: ` }} - <span class="overview__content--total">{{ `${((time % 60000) / 1000).toFixed(0)} secs` }}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div v-for="percentages of questionAnswersPercentages" :key="percentages.questionId" class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/document-2.png" alt="" class="overview__image" />
                    <div class="overview__title">
                        Question {{ parseInt(percentages.questionId) + 1 }} answers percentage
                    </div>
                </div>
                <div class="overview__content">
                    <ul>
                        <li v-for="([answer, percentage], index) in Object.entries(percentages.answer)" :key="index">
                            {{ `"${answer}": ${percentage}%` }}
                        </li>
                    </ul>
                </div>
            </div>

            <div v-for="position of questionAnswersPositions" :key="position.questionId" class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/document-2.png" alt="" class="overview__image" />
                    <div class="overview__title">
                        Question {{ parseInt(position.questionId) + 1 }} answers positions
                    </div>
                </div>
                <div class="overview__content">
                    <table class="overview__table">
                        <thead>
                            <tr>
                                <th>Answer</th>
                                <th>Position 1</th>
                                <th>Position 2</th>
                                <th>Position 3</th>
                                <th>Position 4</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="([answer, positions], index) in Object.entries(position.counts)" :key="index">
                                <td>{{ answer }}</td>
                                <td>{{ positions[0] }}</td>
                                <td>{{ positions[1] }}</td>
                                <td>{{ positions[2] }}</td>
                                <td>{{ positions[3] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="launcher__button-wrapper">
            <button class="launcher__button" @click="$router.back();">
                <UseIcon name="chevron" />
                Home
            </button>
            <button class="launcher__button" @click="generateCsv">
                Export all logs as CSV
            </button>
        </div>
    </div>
</template>

<script setup>
// import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import AnalyticsService, { ANALYTICS_EVENT_TYPES } from '@/services/analytics';
import { ref, computed, onMounted } from 'vue';
import { questions } from '@/questions';

const appName = process.env.VUE_APP_TITLE;
const analyticEvents = ref([]);

const sessions = computed(() => {
    return analyticEvents.value.reduce((acc, event) => {
        if (!acc[event.sessionId]) {
            acc[event.sessionId] = [];
        }

        acc[event.sessionId].push(event);

        return acc;
    }, {});
});

const totalSessions = computed(() => {
    return Object.keys(sessions.value).length;
});

const totalClicks = computed(() => {
    let eventCount = 0;

    for (const sessionId in sessions.value) {
        const events = sessions.value[sessionId];
        eventCount += events.length;
    }

    return eventCount;
});

const avgTimeSpent = computed(() => {
    const averages = {};

    for (const sessionId in sessions.value) {
        const events = sessions.value[sessionId];
        let totalDuration = 0;
        const eventCount = events.length;

        // Iterate over each event in the session
        for (const event of events) {
            const eventTime = new Date(event.time).getTime();
            totalDuration += eventTime;
        }

        // Calculate the average duration per event
        const averageDuration = eventCount > 0 ? totalDuration / eventCount : 0;

        // Store the average duration per session
        if (Math.floor((averageDuration / 1000) % 60) > 0) {
            averages[sessionId] = averageDuration;
        }
    }

    const sum = Object.values(averages).reduce((acc, value) => acc + value, 0);
    const count = Object.keys(averages).length;

    const totalMilliseconds = count > 0 ? sum / count : 0;

    return Math.floor((totalMilliseconds / 1000) % 60) +'secs';
});

const pageViewCount = computed(() => {
    let pageviewCount = 0;

    for (const sessionId in sessions.value) {
        const events = sessions.value[sessionId];

        for (const event of events) {
            if (event.type === ANALYTICS_EVENT_TYPES.PAGEVIEW) {
                pageviewCount++;
            }
        }
    }

    return pageviewCount;
});

const userSessionPerDay = computed(() => {
    const sessionDates = {};

    for (const sessionId in sessions.value) {
        const firstSessionEvent = sessions.value[sessionId][0];
        const sessionDate = new Date(firstSessionEvent.time).toDateString();

        if (sessionDates[sessionDate]) {
            sessionDates[sessionDate]++;
        } else {
            sessionDates[sessionDate] = 1;
        }
    }

    return sessionDates;
});

const interactionsPerDay = computed(() => {
    const eventsPerSession = {};

    for (const sessionId in sessions.value) {
        const firstSessionEvent = sessions.value[sessionId][0];
        const sessionDate = new Date(firstSessionEvent.time).toDateString();
        const events = sessions.value[sessionId];
        const eventCount = events.length;

        if (eventsPerSession[sessionDate]) {
            eventsPerSession[sessionDate] += eventCount;
        } else {
            eventsPerSession[sessionDate] = eventCount;
        }
    }

    return eventsPerSession;
});

const questionViewsCount = computed(() => {
    const questionViewsCount = {};

    analyticEvents.value.forEach(event => {
        if (event.type === ANALYTICS_EVENT_TYPES.PAGEVIEW && event.url.includes('/question/')) {
            const questionId = parseInt(event.url.split('/').pop());
            if (!questionViewsCount[questionId]) {
                questionViewsCount[questionId] = 0;
            }
            questionViewsCount[questionId]++;
        }
    });

    return questionViewsCount;
});

const questionAccuracy = computed(() => {
    const questionAccuracy = {};

    analyticEvents.value.forEach(event => {
        if (event.type === 'question_response') {
            // Skip events that don't have a correct answer
            if (event.correct === '') {
                questionAccuracy[event.questionId] = { title: event.questionTitle, correct: 0, incorrect: 0, accuracy: 0, openQuestion: true }
                return;
            }

            if (!questionAccuracy[event.questionId]) {
                questionAccuracy[event.questionId] = { title: event.questionTitle, correct: 0, incorrect: 0 };
            }

            if (event.correct) {
                questionAccuracy[event.questionId].correct++;
            } else {
                questionAccuracy[event.questionId].incorrect++;
            }
        }
    });

    for (const [questionId, counts] of Object.entries(questionAccuracy)) {
        const total = counts.correct + counts.incorrect;
        questionAccuracy[questionId].accuracy = (counts.correct / total) * 100;
    }

    return questionAccuracy;
});

const averageTimePerQuestion = computed(() => {
    const questionTimes = {};

    analyticEvents.value.forEach(event => {
        if (event.type === ANALYTICS_EVENT_TYPES.PAGEVIEW && event.url.includes('/question/')) {
            const questionId = parseInt(event.url.split('/').pop());
            if (!questionTimes[questionId]) {
                questionTimes[questionId] = {};
            }
            if (!questionTimes[questionId][event.sessionId]) {
                questionTimes[questionId][event.sessionId] = [];
            }

            questionTimes[questionId][event.sessionId].push({ start: new Date(event.time).getTime(), end: null });
        } else if (event.type === ANALYTICS_EVENT_TYPES.QUESTION_RESPONSE) {
            if (questionTimes[event.questionId] && questionTimes[event.questionId][event.sessionId]?.length) {
                const lastQuestionView = questionTimes[event.questionId][event.sessionId].pop();
                questionTimes[event.questionId][event.sessionId].push({ start: lastQuestionView.start, end: new Date(event.time).getTime() });
            }
        }
    });

    const averageTimePerQuestion = {};

    for (const [questionId, sessions] of Object.entries(questionTimes)) {
        const sessionTimes = [];

        for (const session of Object.values(sessions)) {
            session.forEach(({ start, end }) => {
                if (start && end) {
                    sessionTimes.push(end - start);
                }
            });
        }

        averageTimePerQuestion[questionId] = sessionTimes.reduce((acc, time) => acc + time, 0) / sessionTimes.length;
    }

    return averageTimePerQuestion;
});

const questionAnswersPercentages = computed(() => {
    const ids = [0, 1, 3, 4, 5];

    const questionAnswers = []
    for (const question of questions) {
        if (!ids.includes(question.id)) {
            continue;
        }

        const answerPercentages = AnalyticsService.getResponsesPercentageSync(question.id, analyticEvents.value);
        questionAnswers.push({questionId: question.id, answer: answerPercentages});
    }

    return questionAnswers;
});

const questionAnswersPositions = computed(() => {
    const ids = [2];

    const questionPositions = []
    for (const question of questions) {
        if (!ids.includes(question.id)) {
            continue;
        }

        const questionResponses = analyticEvents.value.filter(event => event.type === 'question_response' && event.questionTitle === question.title)
            .map(event => event.answer)

        // for every response answer has to be split by ; and later to calculate how many times each answer was at each position
        const answerCounts = {};
        questionResponses.forEach(response => {
            var answers = response.split(';');
            answers.forEach((answer, index) => {
                if (!answerCounts[answer]) {
                    answerCounts[answer] = { 0: 0, 1: 0, 2: 0, 3: 0 };
                }
                answerCounts[answer][index]++;
            });
        });

        questionPositions.push({questionId: question.id, counts: answerCounts});
    }

    return questionPositions;
});

const averageFeedbackRating = computed(() => {
    const feedbackEvents = analyticEvents.value.filter(event => event.type === ANALYTICS_EVENT_TYPES.SURVEY_FEEDBACK);
    const totalRating = feedbackEvents.reduce((acc, event) => acc + (event.rating || 0), 0);
    return feedbackEvents.length ? totalRating / feedbackEvents.length : 0;
});

onMounted(async () => {
    analyticEvents.value = await AnalyticsService.getEvents();
});

// function generateAnalyticsTable(eventTypes, headers, values) {
//     const rows = [];

//     for (const sessionId in sessions.value) {
//         const events = sessions.value[sessionId];

//         for (const event of events) {
//             if (!eventTypes.includes(event.type)) {
//                 continue;
//             }

//             const row = [
//                 sessionId,
//                 ...values.map(value => Array.isArray(event[value]) ? event[value].join(';') : event[value])
//             ];
//             rows.push(row);
//         }
//     }

//     const csvRows = [['Session ID', ...headers], ...rows].map(row => row.join(',')).join('\n');

//     return csvRows;
// }

// function generatePageviewsTable () {
//     const eventTypes = [
//         ANALYTICS_EVENT_TYPES.PAGEVIEW,
//         ANALYTICS_EVENT_TYPES.MODAL_CLOSE,
//         ANALYTICS_EVENT_TYPES.MODAL_OPEN,
//         ANALYTICS_EVENT_TYPES.VIDEO_PLAY,
//         ANALYTICS_EVENT_TYPES.VIDEO_PAUSE,
//         ANALYTICS_EVENT_TYPES.VIDEO_RESTART,
//         ANALYTICS_EVENT_TYPES.VIDEO_MODAL_OPEN,
//         ANALYTICS_EVENT_TYPES.VIDEO_MODAL_CLOSE
//     ];
//     const headers = ['Event Type', 'URL', 'Event Time'];
//     const values = ['type', 'url', 'time'];

//     return generateAnalyticsTable(eventTypes, headers, values);
// }

// function generateQuestionResponsesTable () {
//     const eventTypes = [
//         ANALYTICS_EVENT_TYPES.QUESTION_RESPONSE
//     ];
//     const headers = ['Event Type', 'Question ID', 'Question Title', 'Answer', 'Correct', 'URL', 'Event Time'];
//     const values = ['type', 'questionId', 'questionTitle', 'answer', 'correct', 'url', 'time'];

//     return generateAnalyticsTable(eventTypes, headers, values);
// }

// function generateSurveyFeedbackTable () {
//     const eventTypes = [
//         ANALYTICS_EVENT_TYPES.SURVEY_FEEDBACK
//     ];
//     const headers = ['Event Type', 'Rating','URL', 'Event Time'];
//     const values = ['type', 'rating', 'url', 'time'];

//     return generateAnalyticsTable(eventTypes, headers, values);
// }

function convertDate (date) {
    return date.split('-').reverse().join('-');
}

async function generateCsv() {
    const events = await AnalyticsService.getEvents();
    const csv = ['sep=,','roomId,sessionId,time,type,url,questionId,questionTitle,answer,correct,rating'];

    events.forEach(event => {
        csv.push([
            event.roomId,
            event.sessionId,
            event.time,
            event.type,
            event.url,
            `${event.questionId || ''}`,
            `"${event.questionTitle || ''}"`,
            `"${event.answer || ''}"`,
            `${event.correct === '' ? '' : event.correct ? 'TRUE' : 'FALSE'}`,
            `${event.rating || ''}`
        ].join(','));
    });

    saveAs(new Blob([csv.join('\n')], { type: 'text/csv;charset=utf-8' }), 'events-log.csv');
}

// async function generateZIP() {
//     const zip = new JSZip();

//     // Add files to the zip

//     //csv
//     const pageviewsCsvContent = generatePageviewsTable();
//     const pageviewsCsvBlob = new Blob([pageviewsCsvContent], { type: 'text/csv' });
//     zip.file('pageviews.csv', pageviewsCsvBlob);

//     const responsesCsvContent = generateQuestionResponsesTable();
//     const responsesCsvBlob = new Blob([responsesCsvContent], { type: 'text/csv' });
//     zip.file('responses.csv', responsesCsvBlob);

//     const feedbackCsvContent = generateSurveyFeedbackTable();
//     const feedbackCsvBlob = new Blob([feedbackCsvContent], { type: 'text/csv' });
//     zip.file('feedback.csv', feedbackCsvBlob);

//     // Generate the zip file
//     const zipContent = await zip.generateAsync({ type: 'blob' });

//     // Save the zip file
//     saveAs(zipContent, 'files.zip');
// }
</script>

